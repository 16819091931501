/* global $ */

$(document).ready(function () {
  $(window).scroll(win.onScroll)
  $(window).resize(win.onResize)

  social.positionize()
  nav.initialize()
  // accordion.initialize()
  view.initialize()
  $('.js-video').lightGallery({
    counter: false,
    loadYoutubeThumbnail: true,
    youtubeThumbSize: 'default',
    loadVimeoThumbnail: true,
    vimeoThumbSize: 'thumbnail_medium'
  })
})
var win = {
  resizeTimeout: null,
  onScroll: function () {
    social.onScroll()
  },
  onResize: function () {
    if (!win.resizeTimeout) {
      clearTimeout(win.resizeTimeout)
    }
    win.resizeTimeout = setTimeout(function () {
      social.positionize()
      view.onResize(function (callback) {
        /* setTimeout(function () {
          accordion.onResize(function () {
            setTimeout(function () { callback() }, 300)
          })
        }, 300) */
        setTimeout(function () { callback() }, 300)
      })
    }, 300)
  }
}

var nav = {
  initialize: function () {
    var navButton = $('.c-navigation-toggle .lines-button')
    var hasImageBehindMenu = $('.c-hero').hasClass('u-cover') // Bool. TRUE / FALSE
    if (hasImageBehindMenu) {
      $('.main-header .c-navigation').addClass('c-navigation--white')
    }
    var navigation = $('.js-navigation')
    $(navButton).click(function (e) {
      nav.navButtonClick.apply(this, [e, navigation])
    })
  },
  navButtonClick: function (e, navigation) {
    e.preventDefault()
    $(this).toggleClass('close')
    $(navigation).toggleClass('is-show')
    if (navigation.hasClass('is-show')) {
      $('body').css('overflow', 'hidden')
    } else {
      $('body').css('overflow', 'visible')
    }
  }
}

var social = {
  onScroll: function () {
    if ($('.c-social--fixed').length) {
      social.checkIfOnTop()
      social.checkIfInBetween()
      social.checkIfOnBottom()
    }
  },
  checkIfOnTop: function () {
    if ($(window).scrollTop() <= ($('.c-hero').offset().top + $('.c-hero').outerHeight() + 100 - ($(window).height() / 2)) && $('.c-social--fixed').is('.show')) {
      $('.c-social--fixed').removeClass('show')
      $('.c-social--block').removeClass('show')
    }
  },
  checkIfInBetween: function () {
    if ($(window).scrollTop() > ($('.c-hero').offset().top + $('.c-hero').outerHeight() + 100 - ($(window).height() / 2)) && $('.c-social--fixed').is(':not(.show)')) {
      $('.c-social--fixed').addClass('show')
      $('.c-social--block').removeClass('show')
    }
  },
  checkIfOnBottom: function () {
    if ($(window).scrollTop() + $(window).height() > $('.c-newsletter').offset().top + 295) {
      $('.c-social--fixed').removeClass('show')
      $('.c-social--block').addClass('show')
    }
  },
  positionize: function () {
    if ($('.c-social--fixed').length) {
      $('.c-social--fixed').css('left', ($(window).width() - 720) / 2 - 80)
    }
  }
}

window.view = {
  height: 0,
  padding: 0,
  selected: 0,
  initialize: function () {
    view.height = 0
    $('[data-id]').each(function () {
      if ($(this).height() > view.height) {
        view.height = $(this).height()
      }
    })
    view.padding = (parseInt($('[data-id]').parent().css('paddingBottom')) + parseInt($('[data-id]').parent().css('paddingTop')))
    $('[data-id]').parent().height(view.height + view.padding).addClass('js-relative')
    $('[data-id]').height(view.height + view.padding).addClass('js-absolute')
    $('[data-id]').addClass('js-hidden')
    if (view.selected === 0) {
      $('[data-id]').eq(0).removeClass('js-hidden')
    } else {
      $('[data-id="' + view.selected + '"]').removeClass('js-hidden')
    }
    $('[data-toggle]').click(function () {
      view.selected = $(this).attr('data-toggle')
      $('[data-id]:not(.js-hidden)').addClass('js-hidden')
      $('[data-id="' + $(this).attr('data-toggle') + '"]').removeClass('js-hidden')
      var _this = this
      $('html,body').animate({
        'scrollTop': ($('[data-id="' + $(_this).attr('data-toggle') + '"]').offset().top - 100)
      }, 300)
    })
  },
  reset: function () {
    $('[data-id]').parent().css('height', 'auto').removeClass('js-relative')
    $('[data-id]').css('height', 'auto').removeClass('js-absolute')
    $('[data-id]').removeClass('js-hidden')
    $('[data-toggle]').off('click')
  },
  onResize: function (_callback) {
    view.reset()

    if (typeof _callback === 'function') {
      _callback(function () {
        view.initialize()
      })
      return
    }
    view.initialize()
  }
}
/*
var accordion = {
  selected: [],
  initialize: function () {
    $('.c-accordion').each(function (ai) {
      var $accordion = $(this)
      if (typeof accordion.selected[ai] === 'undefined') {
        accordion.selected[ai] = 0
      }
      $accordion.children('.c-accordion__item').each(function (i, v) {
        var $accordionItemDesc = $(this).find('.c-accordion__desc')
        $accordionItemDesc.css('height', $accordionItemDesc.outerHeight() + 'px')

        $(this).find('.c-accordion__title').on('click', function () {
          var $accordionTitle = $(this)
          accordion.selected[ai] = $(this).parent().index()
          $accordion.find('.c-accordion__lines').addClass('is-open')
          $accordion.find('.c-accordion__desc').addClass('c-accordion__desc--closed')
          $accordionTitle.find('.c-accordion__lines').removeClass('is-open')
          $accordionTitle.closest('.c-accordion__item').find('.c-accordion__desc').removeClass('c-accordion__desc--closed')
        })
      })
    })
    setTimeout(function () {
      $('.c-accordion').each(function (ai) {
        var $accordion = $(this)
        $accordion.children('.c-accordion__item').each(function (i, v) {
          var $accordionItemDesc = $(this).find('.c-accordion__desc')
          if (i !== accordion.selected[ai]) {
            $accordionItemDesc.addClass('c-accordion__desc--closed')
          }
        })
      })
    }, 600)
  },
  reset: function () {
    $('.c-accordion').each(function () {
      var $accordion = $(this)
      $accordion.children('.c-accordion__item').each(function (i, v) {
        var $accordionItemDesc = $(this).find('.c-accordion__desc')
        $accordionItemDesc.css('height', 'auto')
        $accordionItemDesc.removeClass('c-accordion__desc--closed')
      })
      $accordion.find('.c-accordion__title').off('click')
    })
  },
  onResize: function (_callback) {
    accordion.reset()
    setTimeout(function () {
      accordion.initialize()
      if (typeof _callback === 'function') {
        _callback()
      }
    }, 1000)
  }
} */
$(document).ready(function () {
  $('.js-eq').each(function () {
    var height = 0
    $(this).find('.c-card').each(function () {
      if ($(this).height() > height) {
        height = $(this).height()
      }
    })
    $(this).find('.c-card').height(height)
  })
})
